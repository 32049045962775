import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';

const CSShadowPalaceRewardsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Rewards</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_rewards.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Rewards</h1>
          <h2>List of rewards available in Shadow Palace.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Rewards" />
        <p>
          There are 5 floors in the Shadow Palace and the rewards you can obtain
          increase as you go deeper.
        </p>
        <p>
          Credits, Modules and Appraisal income may vary as you get a random
          amount in every room.
        </p>
        <p>
          Source of the data:{' '}
          <a
            href="https://forum.nexon.com/counterside/board_view?thread=1455518"
            target="_blank"
            rel="noreferrer"
          >
            Nexon Forum
          </a>
          . All credits to 하이브 (HIVE).
        </p>
        <SectionHeader title="Floor 1" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <td></td>
              <th>Room 1</th>
              <th>Room 2</th>
              <th>Room 3</th>
              <th>Room 4</th>
              <th>Room 5</th>
              <th>Full clear</th>
              <th>Total (clear)</th>
              <th>Total (week)</th>
              <th className="total">Total (month)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Credits</td>
              <td>11,883</td>
              <td>11,052</td>
              <td>11,051</td>
              <td>11,006</td>
              <td>11,482</td>
              <td>108,000</td>
              <td>164,474</td>
              <td>1,151,318</td>
              <td className="total">4,934,220</td>
            </tr>
            <tr>
              <td>Shadow Fragments</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td>12</td>
              <td></td>
              <td>15</td>
              <td>63</td>
              <td>441</td>
              <td className="total">1,890</td>
            </tr>
            <tr>
              <td>Appraisal</td>
              <td>9</td>
              <td>9</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>18</td>
              <td>126</td>
              <td className="total">540</td>
            </tr>
            <tr>
              <td>Enchanced Module T4</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>3</td>
              <td></td>
              <td>3</td>
              <td>21</td>
              <td className="total">90</td>
            </tr>
            <tr>
              <td>Advanced Gear Material</td>
              <td></td>
              <td></td>
              <td>10</td>
              <td>9</td>
              <td>10</td>
              <td>20</td>
              <td>49</td>
              <td>343</td>
              <td className="total">1,470</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Floor 2" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <td></td>
              <th>Room 1</th>
              <th>Room 2</th>
              <th>Room 3</th>
              <th>Room 4</th>
              <th>Room 5</th>
              <th>Full clear</th>
              <th>Total (clear)</th>
              <th>Total (week)</th>
              <th className="total">Total (month)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Credits</td>
              <td>11,045</td>
              <td>11,876</td>
              <td>11,857</td>
              <td>11,027</td>
              <td>11,516</td>
              <td>126,000</td>
              <td>183,321</td>
              <td>1,283,247</td>
              <td className="total">5,499,630</td>
            </tr>
            <tr>
              <td>Shadow Fragments</td>
              <td>16</td>
              <td>16</td>
              <td>16</td>
              <td>16</td>
              <td></td>
              <td>19</td>
              <td>83</td>
              <td>581</td>
              <td className="total">2,490</td>
            </tr>
            <tr>
              <td>Appraisal</td>
              <td>11</td>
              <td>11</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>22</td>
              <td>154</td>
              <td className="total">660</td>
            </tr>
            <tr>
              <td>Enchanced Module T4</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>4</td>
              <td></td>
              <td>4</td>
              <td>28</td>
              <td className="total">120</td>
            </tr>
            <tr>
              <td>Advanced Gear Material</td>
              <td></td>
              <td></td>
              <td>11</td>
              <td>12</td>
              <td>11</td>
              <td>40</td>
              <td>74</td>
              <td>518</td>
              <td className="total">2,220</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Floor 3" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <td></td>
              <th>Room 1</th>
              <th>Room 2</th>
              <th>Room 3</th>
              <th>Room 4</th>
              <th>Room 5</th>
              <th>Full clear</th>
              <th>Total (clear)</th>
              <th>Total (week)</th>
              <th className="total">Total (month)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Credits</td>
              <td>12,207</td>
              <td>12,089</td>
              <td>12,239</td>
              <td>11,816</td>
              <td>11,793</td>
              <td>144,000</td>
              <td>204,144</td>
              <td>1,429,008</td>
              <td className="total">6,124,320</td>
            </tr>
            <tr>
              <td>Shadow Fragments</td>
              <td>21</td>
              <td>21</td>
              <td>21</td>
              <td>21</td>
              <td></td>
              <td>24</td>
              <td>108</td>
              <td>756</td>
              <td className="total">3,240</td>
            </tr>
            <tr>
              <td>Appraisal</td>
              <td>12</td>
              <td>10</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>22</td>
              <td>154</td>
              <td className="total">660</td>
            </tr>
            <tr>
              <td>Enchanced Module T4</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>4</td>
              <td></td>
              <td>4</td>
              <td>28</td>
              <td className="total">120</td>
            </tr>
            <tr>
              <td>Advanced Gear Material</td>
              <td></td>
              <td></td>
              <td>13</td>
              <td>11</td>
              <td>12</td>
              <td>60</td>
              <td>96</td>
              <td>672</td>
              <td className="total">2,880</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Floor 4" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <td></td>
              <th>Room 1</th>
              <th>Room 2</th>
              <th>Room 3</th>
              <th>Room 4</th>
              <th>Room 5</th>
              <th>Full clear</th>
              <th>Total (clear)</th>
              <th>Total (week)</th>
              <th className="total">Total (month)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Credits</td>
              <td>12,418</td>
              <td>12,216</td>
              <td>12,279</td>
              <td>12,660</td>
              <td>12,626</td>
              <td>156,000</td>
              <td>218,199</td>
              <td>1,527,393</td>
              <td className="total">6,545,970</td>
            </tr>
            <tr>
              <td>Shadow Fragments</td>
              <td>23</td>
              <td>23</td>
              <td>23</td>
              <td>23</td>
              <td></td>
              <td>27</td>
              <td>119</td>
              <td>833</td>
              <td className="total">3,570</td>
            </tr>
            <tr>
              <td>Appraisal</td>
              <td>11</td>
              <td>12</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>23</td>
              <td>161</td>
              <td className="total">690</td>
            </tr>
            <tr>
              <td>Enchanced Module T4</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>4</td>
              <td></td>
              <td>4</td>
              <td>28</td>
              <td className="total">120</td>
            </tr>
            <tr>
              <td>Advanced Gear Material</td>
              <td></td>
              <td></td>
              <td>12</td>
              <td>13</td>
              <td>12</td>
              <td>70</td>
              <td>107</td>
              <td>749</td>
              <td className="total">3,210</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Floor 5" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <td></td>
              <th>Room 1</th>
              <th>Room 2</th>
              <th>Room 3</th>
              <th>Room 4</th>
              <th>Room 5</th>
              <th>Full clear</th>
              <th>Total (clear)</th>
              <th>Total (week)</th>
              <th className="total">Total (month)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Credits</td>
              <td>12,792</td>
              <td>13,004</td>
              <td>12,943</td>
              <td>12,823</td>
              <td>12,766</td>
              <td>162,000</td>
              <td>226,328</td>
              <td>1,584,296</td>
              <td className="total">6,789,840</td>
            </tr>
            <tr>
              <td>Shadow Fragments</td>
              <td>24</td>
              <td>24</td>
              <td>24</td>
              <td>24</td>
              <td></td>
              <td>30</td>
              <td>126</td>
              <td>882</td>
              <td className="total">3,780</td>
            </tr>
            <tr>
              <td>Appraisal</td>
              <td>12</td>
              <td>12</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>24</td>
              <td>168</td>
              <td className="total">720</td>
            </tr>
            <tr>
              <td>Enchanced Module T4</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>4</td>
              <td></td>
              <td>4</td>
              <td>28</td>
              <td className="total">120</td>
            </tr>
            <tr>
              <td>Advanced Gear Material</td>
              <td></td>
              <td></td>
              <td>13</td>
              <td>14</td>
              <td>14</td>
              <td>75</td>
              <td>116</td>
              <td>812</td>
              <td className="total">3,480</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Conclusion" />
        <ul>
          <li>
            if you can clear Floor #2 Room 4 the rewards are better than fully
            clearing Floor #1,
          </li>
          <li>
            if you can clear Floor #3 Room 4 the rewards are better than fully
            clearing Floor #2,
          </li>
          <li>
            if you can fully clear Floor #4 the rewards are better than fully
            clearing Floor #3,
          </li>
          <li>
            if you can fully clear Floor #5 the rewards are better than fully
            clearing Floor #4,
          </li>
          <li>
            the total difference in Shadow Fragments between Floor#3 and Floor#4
            is only 11,
          </li>
          <li>
            the total difference in Shadow Fragments between Floor#4 and Floor#5
            is only 7.
          </li>
        </ul>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceRewardsPage;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace rewards guide | Counter Side | Prydwen Institute"
    description="List of rewards available in Shadow Palace."
  />
);
